<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="
        login login-1 login-signin-on
        d-flex
        flex-column flex-lg-row flex-row-fluid
        bg-white
      "
      id="kt_login"
    >
      <!--begin::Aside-->
      <div
        v-if="!checkIfRegister"
        class="
          bg-light
          login-aside
          d-flex
          bgi-size-cover bgi-no-repeat
          p-10 p-lg-10
          mr-lg-12
        "
        :style="{ backgroundImage: `url(${backgroundImage})` }"
      >
        <!--begin: Aside Container -->
        <div class="d-flex flex-row-fluid flex-column justify-content-between">
          <!--begin: Aside header -->

          <!--end: Aside header -->
          <!--begin: Aside content -->
          <div
            class="flex-column-fluid d-flex flex-column justify-content-center"
          >
            <!-- <p class="font-weight-lighter text-white opacity-80">
              
            </p> -->
          </div>
          <!--end: Aside content -->
          <!--begin: Aside footer for desktop -->
          <div
            class="
              d-none
              flex-column-auto
              d-lg-flex
              justify-content-between
              mt-10
            "
          >
            <div class="opacity-70 font-weight-bold">© 2020 SmartID</div>
            <div class="d-flex">
              <a href="#" class="text-dark">Privacy</a>
              <a href="#" class="text-dark ml-10">Legal</a>
              <a href="#" class="text-dark ml-10">Contact</a>
            </div>
          </div>
          <!--end: Aside footer for desktop -->
        </div>
        <!--end: Aside Container -->
      </div>
      <!--begin::Aside-->

      <!--begin::Content-->
      <div
        class="
          d-flex
          flex-column flex-row-fluid
          position-relative
          p-7
          overflow-hidden
        "
      >
        <router-view></router-view>
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "auth",
  methods: {},
  beforeMount() {},
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
    ...mapGetters(["isAuthenticated"]),
    backgroundImage() {
      return process.env.BASE_URL + "media/bg/pointgate-bg.jpg";
    },

    checkIfRegister() {
      return this.$route.name === "register";
    },
  },
};
</script>
